.calculator__container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 3rem;
}

.calculator__container {
  border-radius: 0 3rem 0 1rem;
  background: none;
  box-shadow: 0 4px 4px 0 rgba(44, 120, 44, 0.897);
  backdrop-filter: blur(29px);
  -webkit-backdrop-filter: blur(20px);
  background: white;
  padding: 5rem;
}

.calculator__container:hover {
  border-radius: 0rem;
  transition: var(--transition);
}

.calculator__container button {
  padding: 1rem;
  margin-top: 1rem;
  border-radius: 0.6rem;
  font-size: 1.4rem;
  cursor: pointer;
}

.calculator__heading {
  display: flex;
  justify-content: center;
  margin: 1rem;
  margin-bottom: 4rem;
}

.calc__icon {
  display: flex;
  padding: 1rem;
  box-shadow: 0 4px 4px 0 rgba(9, 40, 9, 0.897);
  backdrop-filter: blur(29px);
  -webkit-backdrop-filter: blur(20px);
  border-radius: 16px 12px 16px 12px;
  background: white;
  color: rgb(218, 141, 17);
}

.calc__heading {
  margin-top: 1rem;
  padding-left: 1.6rem;
}

/* .calculator__container li {
  padding: 1rem;
  box-shadow: 0 1px 1px 0 rgba(9, 40, 9, 0.897);
  backdrop-filter: blur(9px);
  -webkit-backdrop-filter: blur(20px);
  border-radius: 6px 0px 6px 6px;
  font-size: 1.2rem;
} */

.icon__container img {
  height: 6rem;
  width: 6rem;
}

.rooftop__container {
  padding-right: 2rem;
}
.consumption__container {
  border-right: 2px solid rgba(126, 124, 120, 0.208);
  padding-right: 2rem;
}

@media screen and (max-width: 600px) {
  .calculator__container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 3rem;
  }

  .rooftop__container {
    border-bottom: 2px solid rgba(126, 124, 120, 0.208);
    padding: 1rem;
  }
  .consumption__container {
    border-bottom: 2px solid rgba(126, 124, 120, 0.208);
    border-right: 0;
    padding: 1rem;
  }
}
