.carbon-audit {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.step {
  display: flex;
  width: 100%;
  padding: 20px;
  background-color: white;
  box-shadow: 0 4px 4px 0 rgba(44, 120, 44, 0.897);
  transition: background-color 0.3s ease;
  border-radius: 14px;
}

.step:hover {
  background-color: #ffffffe8;
}

.step-number {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: rgb(237, 157, 10);
  margin-right: 20px;
  font-size: 18px;
  background-color: white;
}

.step {
  flex-grow: 1; /* Allow the content to expand */
  display: flex;
  flex-direction: column;
}

.step-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.step-title-text {
  font-size: 16px;
  color: #333;
}
