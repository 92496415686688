.work__container {
  display: flex;
  gap: 6rem;
  align-items: center;
  justify-content: center;
  margin: 2rem 0 2rem 0;
  padding: 0 0 0 2rem;
}

.text__work > p,
h3 {
  padding: 0.3rem 0 0.3rem 0;
}

.text__work {
  box-shadow: 0 1px 1px 0 rgb(9 40 9 / 90%);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  background: rgb(232, 231, 230);
  padding: 1rem;
}

.image__work > img {
  width: 50vmin;
  height: 24vmin;
  object-fit: cover;
  object-position: center;

  box-shadow: 0 1px 1px 0 rgb(9 40 9 / 90%);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  background: white;
  /* padding: 1rem; */
}

.table-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

table {
  font-family: Arial, sans-serif;
  border-collapse: collapse;
  width: 90vw;
  margin-bottom: 20px;
  background: white;
  box-shadow: 0 4px 6px rgba(219, 138, 24, 0.1);
  border-radius: 12px;
}

th,
td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

thead {
  background-color: #f2f2f2;
}

th {
  background-color: rgba(241, 113, 8, 0.808);
  color: #fff;
  font-size: 1.6rem;
  font-weight: 600;
}

tr:hover {
  background-color: #f4f4f4cd;
  transition: 100ms;
}
