.main__header{
    width: 100vw;
    height: calc(100vh - 3rem);
    display: grid;
    place-items: center;
    margin-top: 3rem;
}

.main__header-container{
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content: space-between;
    gap: 6rem;
}

.main__header-left {
margin-top: -3rem;
animation: main__header__left 1s ease 0.1s 1 normal forwards;

}
.main__header-left .btn{
    background-color: #4598f7;
}

@keyframes main__header__left {
	0% {
		opacity: 0;
		transform: translateX(-50px);
	}

	100% {
		opacity: 1;
		transform: translateX(0);
	}
}


.main__header-left h4 {
    margin-bottom: 1rem;
    color: black;
    font-weight: 500;
    font-size: 1.1rem;
    animation: company__title 1.9s ease 0.1s 1 normal forwards;
}

@keyframes company__title {
	0% {
		opacity: 0;
		transform: translateX(-50px);
	}

	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

.main__header-left p {
    margin: 1rem 0 3.5rem;
    font-size: 1.1rem;
}

.main__header-right {
    display: grid;
    place-items: center;
    position: relative;
    animation: main__header__right 1s ease 0.1s 1 normal forwards;
}
@keyframes main__header__right {
	0% {
		opacity: 0;
		transform: translateX(50px);
	}

	100% {
		opacity: 1;
		transform: translateX(0);
	}
}


.changing__text::before {
        content: " ";
        animation: animate infinite 9s;
        font-weight: 900;
        color: #1aea5c;
        transition: var(--transition);
        
}

@keyframes animate {
    0% {
        content: "Finance, TAX, Assurance, M&A";
    }
    20% {
        content: "Media & Marketing";
    }
    40% {
        content: "IT & ITES";
    }
    60% {
        content: "Sustainibilty & Agritech";
    }
    80% {
        content: "Legal Services";
    }
    100% {
        content: "Human Resource Management";
    }
}

.main__header-circle {
    width: 25rem;
    height: 25rem;
    /* background: linear-gradient(75deg, var(--color-secondary), transparent); */
    border-radius: 50%;
    position: absolute;
    transition: var(--transition);
    /* filter: blur(15px); */
}

.main__header-image {
    position: relative;
}

.main__header-right:hover .main__header-circle {
    filter: blur(15px);
}



/* ====================PROGRAMS============ */

.programs {
    margin-top: 3rem;

}

.programs__wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr); 
    gap: 3rem;
    margin-top: 3rem;
}

.programs__program a {
    margin-top: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.programs__program:hover a {
    background: var(--color-secondary);
    color: white;
}




/* ================VALUES============= */

.values__container{
    display: grid;
    grid-template-columns: 38% 50%;
    gap: 12%;
   
}

.values__image {
    transition: var(--transition);
}

.values__image > img{
    height: 82vh;
}



.values__right > p{
    margin: 1.5rem 0 5rem;

}



.values__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0rem 2.4rem;
    
}


.card.values__value {
    padding-top: 3.5rem ;
    text-align: left;
    position: relative;
    border-radius: 4%;
}

.card.values__value small{
    color: black;
    font-weight: 400;
}

.card.values__value span {
    position: absolute;
    top: -1.5rem;
    color: rgb(183, 115, 14);

}




.btn-adv{
    margin-top: 3rem;
    display: flex;
    gap: 2rem;
    justify-content: space-around;
}


/* ================= FAQs================ */

.faqs__wrapper {
    margin-top: 4rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem 5rem;
    align-items: flex-start;

}

.faq {
    background: var(--color-gray-500);
    padding: 2rem;
    border-radius: 1rem;
    cursor: pointer;
}

.faq div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.faq__icon{
    background: transparent;
    color: var(--color-gray-100);
    font-size: 1.5rem;
}

.faq p {
    margin-top: 1.5rem;

}


/* ===========testimonials========= */

.testimonials {
    width: 50rem;
    margin-inline: auto;
}

.testimonials__head {
    justify-content: center;
    margin-bottom: 1.2rem;

}

.card.testimonial {
    position: relative;
    text-align: left;
    margin-top: 5rem;
    border-radius: 0 3rem 0 3rem;
}


.testimonial__avatar {
    width: 4rem;
    height: 4rem;
    border-radius: 1.5rem ;
    border: 2px solid transparent;
    overflow: hidden;
    position: absolute;
    top: -2rem;
    left: calc(50% -2rem);
    box-shadow: 0 1.5rem 2rem rgba(26, 26, 54, 0.4);
    transition: var(--transition);
}

.testimonial:hover .testimonial__avatar{
    border-radius: 50%;
    border-color: var(--color-gray-400);
}


.testimonial__quote {
    font-style: italic;
    margin: 1rem 0 2rem;
}


.card small.testimonial__title {
    margin-top: 0.3rem;
}


.testimonials__btn-container {
    width: fit-content;
    margin: 2.5rem auto 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.testimonials__btn {
    background: transparent;
    cursor: pointer;
}

.testimonials__btn svg {
    fill: orange;
    font-size: 1.8rem;
    width: 3rem;
    height: 3rem;
}


.testimonials__btn svg:hover{
    fill: #17a58d;

}





/* ===========================Footer=============== */

footer {
    background: linear-gradient(171deg, rgba(255,255,255,1) 40%, rgba(254,113,22,0.7486344879748774) 130%);
    margin-top: 7rem;
    padding-top: 7rem;
    font-size: 0.9rem;
    color: black;
}

.footer__container {
    display: grid;
    grid-template-columns: 26rem 1fr 1fr 1fr;
    gap: 6rem;

}

.footer__container article {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.7rem;
}

.footer__container article p {
    margin-top: 0.5rem;

}

.footer__container a:hover{
    color: #17a58d;
}

.footer__container article h4 {
    margin-bottom: 0.6rem;
    font-size: 1rem;
}

.footer__socials {
    margin-top: 1.5rem;
    display: flex;
    gap: 1rem;
    align-items: center;

}

.footer__socials a {
    background: rgba(0, 0, 0, 0.715);
    padding: 0.7rem;
    border: 1px solid transparent;
    border-radius: 0.6rem;
    transition: var(--transition);


}



.footer__socials a svg {
    background: white;

}

.footer__socials a:hover {
border-color: green;
background: transparent;
color: #17a58d;
}

.footer__copyright {
    color: var(--color-gray-100);
    text-align: center;
    padding: 1.5rem 0;
    border-top: 2px solid var(--color-primary-variant);
    margin-top: 5rem;
}

/* Media quries medium screens */
@media screen and (max-width: 1024px) {
    /* main header section  */
    .main__header {
        height: fit-content;
        padding: 12rem 0;


    }
  
.main__header-container {
    gap: 0;
}

.main__header-circle{
    width: 16rem;
    height: 16rem;

}

/* programs */
.programs__wrapper {
    grid-template-columns: 1fr 1fr;

}

/* values */
.values__container {
    grid-template-columns: 1fr;
    justify-content: center;
    gap: 4rem;
}

.values__wrapper{
    gap: 4rem 3rem;
}
.values__image{
    display: none;
}

/* FAQS */

.faqs__wrapper{
    grid-template-columns: 1fr;
}

/* testimonials */

.testimonials {
    width: 75%;
}

/* footer */
.footer__container {
    grid-template-columns: 1fr 1fr;
gap: 4rem;
}



  }


  /* Media quries small screens */
@media screen and (max-width: 600px) {

    .main__header{
        margin-top: 0;
        padding: 12rem 0 0;
        height: 100vh;
    }

    .main__header-image, .main__header-circle {
    display: none;
    }
.main__header-container {
    grid-template-columns: 1fr;

}

.programs__wrapper {
    grid-template-columns: 1fr;
    gap: 2rem;
    margin-top: 3rem;

}
.programs__program {
    width: 84%;
    margin: 0 auto;
}


.values__wrapper {
grid-template-columns: 1fr;
gap: 3rem;

}

.card.values__value {
    width: 84%;
    margin-inline: auto;
}

.btn-adv{
    display: grid;
    align-items: center;
}

.faqs__wrapper {
    gap: 1rem;
    margin-top: 3rem;

}

.testimonials {
    width: 100%;
}

footer {
    margin-top: 7rem;

}
.footer__container {
    grid-template-columns: 1fr;
    gap: 3rem;
}

.footer__container article {
    align-items: center;
}

.footer__container article p {
    text-align: center;
}

}
