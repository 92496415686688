.trainers__section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.team__heading{
    text-align: center;
    margin-top: 1rem;
    color: rgb(20, 26, 3);
}


.trainers__container {
    display: flex;
    gap: 6rem;
    margin: 2rem;
    box-shadow: 0 2px 2px 0 rgb(33, 148, 83);
    backdrop-filter: blur( 20px );
    background-color: white;
    -webkit-backdrop-filter: blur( 20px );
    border-radius: 10px;
    /* border: 1px solid rgba(245, 176, 28, 0.925); */
    
}

.trainers__container:hover{
    background: whitesmoke
    
}

.team__image img {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    
}

.team__image h3 {
    padding-top: 0.4rem;
}

.team__image {
    margin: 0.4rem;
    padding: 0.4rem;
}

.team__description{
    margin-top: 0.4rem;
    padding: 0.7rem;
}




@media screen and (max-width:600px) {
    .trainers__container{
        display: grid;
        gap: 1rem;
    }
}

@media screen and (max-width:1024px) {
    .trainers__container {
        display: grid;
        gap: 1rem;
    }
}