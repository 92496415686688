/* Add this CSS to your component's stylesheet or a separate CSS file */

.accordion {
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  margin-bottom: 10px;
  overflow: hidden;
  box-shadow: 0 4px 4px 0 rgba(44, 120, 44, 0.897);
  background-color: #ffffff;
}

.accordion-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  cursor: pointer;
  background-color: #f5f5f5;
}

.accordion-header:hover {
  background-color: #ffffffe5;
}

.accordion-title {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}

.icon {
  width: 20px;
  height: 20px;
  background-color: transparent;
  border: 2px solid #333333;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease-in-out;
  transform-origin: center;
}

.icon.open {
  transform: rotate(180deg);
  transition: 1s ease;
}

.accordion-content {
  padding: 15px;
  font-size: 1.1rem;
  color: #555555;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .accordion-title {
    font-size: 14px;
  }
  .accordion-content {
    font-size: 12px;
  }
}
