.image__slide{
 margin: 6rem;

}


.image__slide img{
    width: 600px;
    /* border: 2px solid rgb(230, 152, 8) ; */
    height: 500px ;
    border-radius: 4px;
}

@media screen and (max-width: 600px){
    .image__slide{
        margin: 0.1rem;
    }
}
