.solution__container > h3{
    text-align: center;
    margin: 5rem 4rem 2rem 4rem;
    font-weight: 500;
    font-size: 1.2rem;
}

.solution__wrapper{
    display: flex;
    justify-content: center;
    gap: 3rem;
    align-items: center;
    flex-wrap: wrap;

}

