.about__section-image {
  border-radius: 4px;
  overflow: hidden;
  /* transform: skew(15deg); */
  transition: var(--transition);
}

.about__section-content h1 {
  margin-bottom: 2rem;
}

.about__section-content p {
  margin-bottom: 1rem;
}

.about__story-container,
.about__mission-container {
  display: grid;
  grid-template-columns: 40% 50%;
  gap: 10%;
  /* border-bottom: 2px solid rgba(126, 124, 120, 0.208); */
  padding-bottom: 1rem;
}

.about__story-container {
  border-bottom: 2px solid rgba(126, 124, 120, 0.208);
}

.about__vision-container {
  display: grid;
  grid-template-columns: 50% 40%;
  gap: 10%;
}

.advantage__wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 3rem;
  margin: 4rem 8rem 0 8rem;
}

.details__container {
  margin-top: 6rem;
  border-bottom: 2px solid rgba(126, 124, 120, 0.208);
  padding-bottom: 2rem;
}

.details__container > h2,
h5 {
  margin: 0.6rem;
  text-align: center;
}

.details__container > h5 {
  font-weight: 300;
  font-size: 1.2rem;
  margin-top: 0.6rem;
}

.details__container h2 > span {
  color: rgb(237, 157, 10);
  box-shadow: 0 0px 2px 0 rgba(13, 73, 13, 0.897);
  padding: 0.3rem;
  backdrop-filter: blur(29px);
  -webkit-backdrop-filter: blur(20px);
  border-radius: 4px;
  background: white;
}

.teams__container {
  border-bottom: 2px solid rgba(126, 124, 120, 0.208);
  padding-bottom: 2rem;
}

.gallery__container {
  border-bottom: 2px solid rgba(126, 124, 120, 0.208);
  padding-bottom: 2rem;
}

.updated__vision-container h3 {
  text-align: center;
  padding-bottom: 1rem;
  color: orange;
  font-weight: 800;
  font-style: italic;
  font-size: 2.6rem;
}

.updated__vision-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 7rem;
}

.updated__vision-container li {
  margin: 0 2rem 0 2rem;
}

.updated__vision-container h4 {
  padding-bottom: 1rem;
  color: black;
  font-weight: 600;
  font-size: 2.6rem;
}

.updated__vision-container > h4 > span {
  color: rgb(237, 157, 10);
  box-shadow: 0 0px 2px 0 rgba(13, 73, 13, 0.897);
  padding: 0.3rem;
  backdrop-filter: blur(29px);
  -webkit-backdrop-filter: blur(20px);
  border-radius: 4px;
  background: white;
}

/* Media quries medium screens */
@media screen and (max-width: 1024px) {
  .about__section-image {
    width: 60%;
  }

  .about__section-content h1 {
    margin-bottom: 1.2rem;
  }

  .about__story-container,
  .about__vision-container,
  .about__mission-container {
    grid-template-columns: 1fr;
    gap: 3rem;
  }

  .about__vision .about__section-image {
    grid-row: 1;
  }
}

/* Media quries small screens */
@media screen and (max-width: 600px) {
  .about__section-image {
    width: 80%;
    margin-inline: auto;
  }

  .about__story-container,
  .about__vision-container,
  .about__mission-container {
    gap: 2rem;
  }

  .details__container {
    display: grid;
    grid-template-columns: 1fr;
  }

  .advantage__wrapper {
    display: grid;
    grid-template-columns: 1fr;
    gap: 3rem;
    margin: 2rem 0rem 0 0rem;
  }

  .gallery__container {
    display: hidden;
  }
}
