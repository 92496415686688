.applications__wrapper{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4rem;
    margin: 4rem;
}

.applications__container > h3{
text-align: center;
margin: 5rem 4rem 2rem 4rem;
font-weight: 500;
font-size: 1.2rem;
}


@media screen and (max-width: 600px){
    .applications__wrapper{
        grid-template-columns: 1fr;
        margin: 0.4rem;
    }

    .applications__container{
        margin: 0.1rem;
    }

}