.info__heading{
display: flex;
justify-content: left;
margin: 1rem;

}

.info__container p{
    font-weight: 500;
    margin: 1.1rem;
    
}

.info__image{
    width: 40px;
    height: 40px;
    margin-right: 1rem;
}

.info__heading > h2{
    display: flex;
    padding: 1rem;
    box-shadow: 0 4px 4px 0 rgba(13, 73, 13, 0.897);
backdrop-filter: blur( 29px );
-webkit-backdrop-filter: blur( 20px );
border-radius: 16px 0px 16px 12px;
background: white;
}

.changing__texts{
    
margin-left: 0.4rem;
margin-right: 0.4rem ;

}

.changing__texts::before {
    content: "Choose";
    animation: animated infinite 1.7s;
    font-weight: 900;
    color: #17a58d;
    transition: var(--transition);
    
}




.choose__wrapper{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 3rem;
    margin: 2rem 8rem 0 8rem;
}



.card.choose__value span {
    position: absolute;
    top: -1.5rem;
    color: green;

}

.card.choose__value span svg{
    height: 36px;
    width: 36px;
}

.card.choose__value {
    border-radius: 0 3rem 0 1rem;
    background: none;
    box-shadow: 0 4px 4px 0 rgba(44, 120, 44, 0.897);
    backdrop-filter: blur( 29px );
    -webkit-backdrop-filter: blur( 20px );
    background: white;
}

.card.choose__value:hover {
    border-radius: 0%;
    transition: var(--transition);
}

@media screen and (max-width:1024px){
    .choose__wrapper{
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width:600px){
    .choose__wrapper{
        grid-template-columns: 1fr;
        margin: 2rem 3rem 0 3rem;


    }
}