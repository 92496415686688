*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
}

:root {
  /* --primary-hue: 90;     Color */
  /* --gray-hue: 120; */
  /* --color-primary: hsl(var(--primary-hue), 60%, 50%);        saturation 100% and lightness is 50% */
  /* --color-primary-variant: hsl(var(--primary-hue), 100%, 65%); */
  --color-primary: rgb(255, 255, 255);
  --color-primary-variant: rgb(0, 0, 0);
  --color-secondary: rgba(241, 113, 8, 0.977); /*hovering*/
  --color-gray-100: rgba(12, 12, 12, 0.925); /*Most of text color*/
  --color-gray-200: rgba(8, 8, 0, 0.932); /*paragraph or description color*/
  --color-gray-300: rgba(231, 242, 15, 0.966);
  --color-gray-400: #63e287; /*border color after hover*/
  --color-gray-500: rgb(255, 255, 255); /*card color*/
  --color-gray-600: rgba(126, 124, 120, 0.208); /*background*/
  /* --color-gray-600: white; */

  --container-width-lg: 80%;
  --container-width-md: 90%;

  --transition: all 500ms ease;
}

/* general styles */

body {
  font-family: "Montserrat", sans-serif;
  color: var(--color-gray-200);
  line-height: 1.7;
  overflow-x: hidden;
  background: var(--color-gray-600);
  /* background-size: 300% 300%;
    animation: color 18s ease-in infinite; */
}

/* @keyframes color {
    0% {
        background-position: 30 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 30 50%;
    }
} */

.container {
  width: var(--container-width-lg);
  max-width: 1920px;
  margin-inline: auto;
}

h1,
h2,
h3,
h4,
h5 {
  line-height: 1.2;
  color: black;
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2rem;
}

a {
  color: var(--color-gray-100);
}

img {
  display: block;
  object-fit: cover;
  width: 100%;
}

.btn {
  color: white;
  width: fit-content;
  margin: 0 auto;
  padding: 0.9rem 2rem;
  background: #17a58d;
  border-radius: 1.5rem;
  transition: var(--transition);
}

.btn:hover {
  background: #32b161;
  color: white;
}

.btn.lg {
  padding: 1.2rem 3rem;
  border-radius: 2rem;
  font-size: 1.1rem;
}

.btn.sm {
  padding: 0.4rem 1.2rem;
  font-size: 0.9rem;
}

.btn.sm:hover {
  background: rgba(11, 151, 11, 0.808);
}

section {
  margin-top: 10rem;
}

.section__head {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.section__head span {
  background: var(--color-gray-500);
  padding: 0.8rem;
  border-radius: 1rem;
  color: var(--color-secondary);
  font-size: 1.5rem;
}

.card {
  background: linear-gradient(
    10deg,
    rgba(255, 255, 255, 1) 60%,
    rgba(240, 161, 43, 0.548) 100%
  );
  border: 2px solid transparent;
  box-shadow: 0 2px 12px 0 rgba(15, 75, 5, 0.906);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  text-align: center;
  padding: 3rem 2rem;
  border-radius: 11rem;
  transition: var(--transition);
}

.card.value__solution {
  border-radius: 1rem;
}

.card.applications__value {
  border-radius: 1rem;
}

.card:hover {
  background: whitesmoke;
  border-color: #17a58d;
  cursor: default;
}

.card span {
  width: 3rem;
  height: 3rem;
  background: var(--color-primary);
  color: var(--color-gray-100);
  font-size: 1.5rem;
  padding: 0.8rem;
  display: grid;
  place-items: center;
  margin-bottom: 1.5rem;
  margin-inline: auto;
  border-radius: 1rem;
  transition: var(--transition);
}

.card:hover span {
  background: var(--color-secondary);
  color: white;
}

.card small {
  margin-top: 1rem;
  display: block;
  font-weight: 500;
  color: black;
}

.header {
  margin-top: 5rem;
  height: 20rem;
  overflow: hidden;
  border-bottom: 2px solid var(--color-gray-400);
}

.header__container {
  width: 100%;
  height: 100%;
  position: relative;
  display: grid;
  place-items: center;
  background: black;
}

.header__container-bg {
  position: absolute;
  width: 100%;
}

.header__container-bg img {
  opacity: 0.3;
}

.header__content {
  position: relative;
  width: 44%;
  margin: 0 auto;
  text-align: center;
  color: var(--color-gray-100);
}

.header__content h2 {
  margin-bottom: 1rem;
  color: white;
}

.header__content p {
  color: rgba(255, 255, 255, 0.963);
}

/* Media quries medium screens */
@media screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-md);
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.6rem;
  }

  .header__content {
    width: 76%;
  }
}

/* Media quries small screens */
@media screen and (max-width: 600px) {
  h1 {
    font-size: 2.2rem;
    line-height: 1.3;
  }

  section {
    margin-top: 7rem;
  }
  .header {
    height: fit-content;
  }

  .header__content {
    width: var(--container-width-md);
    padding: 3rem 0;
  }

  .header__content p {
    font-size: 0.85rem;
  }
}
