 
.banner__content{
    position: absolute;
    top: 10%;
    margin: 1rem;
}

.banner__content > h1{
    color: rgb(255, 255, 255);
    margin: 2rem;
    font-size: 3rem;
    border-left: 0.1rem solid white;
    padding-left: 0.2rem;
}


.landing >img{
    height: 90vh;
    width: 100vw;
}

.landing > img:hover{
    scale: 1.01;
    transition: var(--transition);
}

.banner__content:hover{
    scale: 1.10;
    transition: var(--transition);

    
}