.container.contact__container{
    width: 58;
    display: grid;
    grid-template-columns: 30% 58%;
    gap: 12%;

    padding: 1.2rem;
    
    
    background:  white;
    /* box-shadow: 0 8px 32px 0 rgba(27, 28, 27, 0.897);
    backdrop-filter: blur( 20px );
    -webkit-backdrop-filter: blur( 20px );
    border-radius: 10px;
    border: 1px solid rgba(64, 245, 28, 0.925); */
    }

    .contact__heading{
        display: flex;
        flex-direction: column;
        place-items: center;
        padding-bottom: 4rem;
    }
    
    .contact__options {
        display: flex;
        flex-direction: column;
        gap: 1.2rem;
    }
    
    .contact__option {
    background:  white ;/*background*/
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition);
    }
    
    .contact__option:hover{
        background-color: rgba(248, 247, 247, 0.699);
        border-color: #17a58d;
    }
    
    .contact__option-icon {
        font-size: 2.5rem;
        color: #17a58d;
        margin-bottom: 0.5rem;
    }
    
    .contact__option a {
        margin-top: 0.7rem;
        display: inline-block;
        font-size: 1rem;
        color: #17a58d;
    }
    
    .contact__option a:hover{
        color: green;
    }

    /* ===================  FORM===================== */
    
    form{
        display: flex;
        flex-direction: column;
        gap: 1.2rem;
    
        
    }
    
    input, textarea {
        width: 100%;
        padding: 1.5rem;
        
        border-radius: 0.5rem;
        background: white;
        border: 2px solid rgba(255, 166, 0, 0.651);
        resize: none ;
        color: black;
    
    }
    .note{
        /* margin-left: 37rem; */
        display: flex;
        justify-content: space-between;
        height: 6rem;
        width: 6rem;

    }
    
    
    /* ================MEDIA QURIES FOR TAB============= */
    @media screen and (max-width: 1024px) {
        .container.contact__container{
            grid-template-columns: 1fr;
            gap: 2rem;
        }
    }
    
    
    /* =======================MEDIA QURIES (Mobile)=================== */
    
    @media screen and (max-width: 600px) {
       
      .container.contact__container{
        width: var(--container-width-md);
      }

      .note{
        display: none;
      }
    
    }


